export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);
export const isActive = (value) => (window.location.pathname === value);
export const objToQuerystring = (obj) => {
  let queryString = '';
  if (obj && Object.keys(obj).length > 0) {
    Object.keys(obj).map((key) => {
      if (Array.isArray(obj[parseInt(key, 10)])) {
        queryString += `${key}=${obj[parseInt(key, 10)].toString()}&`;
      } else {
        queryString += `${key}=${obj[parseInt(key, 10)]}&`;
      }
      return null;
    });
    // remove last &
    queryString = queryString.slice(0, -1);
  }
  return queryString;
};
export const convertToKey = (value) => (value ? value.toLowerCase().replace(/\s/gi, '_') : 'Nothing to convert');
export const splitCourseSectionTopicId = (courseSectionTopicId) => {
  const idValues = courseSectionTopicId ? courseSectionTopicId.split('|') : [];
  return {
    courseId: idValues[0],
    sectionId: idValues[1],
    topicId: idValues[2],
  };
};

export const randomId = (min = 19999, max = 59998) => {
  const minA = Math.ceil(min);
  const maxA = Math.floor(max);
  return Math.floor(Math.random() * (maxA - minA + 1)) + minA;
};

export const highlightSpeechText = (textContainer, speechMarks) => {
  if (textContainer) {
    const container = document.querySelector(textContainer);
    if (container) {
      const words = container.querySelectorAll('[data-text]');
      words.forEach(
        (currentValue, currentIndex) => {
          if (
            currentValue
            && currentValue.dataset
            && currentValue.dataset.text
            && currentValue.dataset.text.trim() !== ''
            && speechMarks[currentIndex]
            && speechMarks[currentIndex].time
          ) {
            currentValue.style.opacity = 0.4;
            const speechMark = speechMarks[currentIndex];
            setTimeout(() => {
              currentValue.style.opacity = 1;
            }, speechMark.time);
          }
        },
      );
    }
  }
};
