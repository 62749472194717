import { createTheme } from '@mui/material/styles';

const getComponents = (mode) => {
  const defaultTheme = createTheme({
    palette: {
      mode,
    },
  });
  return {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: '25px',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontWeight: 600,
        },
        outlined: {
          borderRadius: '25px',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontWeight: 600,
        },
        text: {
          borderRadius: '25px',
          paddingLeft: '8px',
          paddingRight: '8px',
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            minWidth: '160px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
};

export default getComponents;
