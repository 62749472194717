const secondary = {
  50: '#E7F5E4',
  100: '#C5E7BD',
  200: '#9ED793',
  300: '#74C865',
  400: '#52BC41',
  500: '#2AB00F',
  600: '#1EA100',
  700: '#038F00',
  800: '#007E00',
  900: '#006000',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853',
};

export default secondary;
